import { Button } from "primereact/button";
import { descargaDocumentosProveedorService } from "../../../services/reactivaService";
import { useState } from "react";

import { BlockUI } from 'primereact/blockui';

const DescargaDocumentosProveedor = ({proveedor}) => {

    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
	   if (!loading){
		
       setLoading(true);
			try {
				const response = await descargaDocumentosProveedorService(proveedor.id);
				const blob = new Blob([response.data], { type: 'application/zip' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `${proveedor.nombre}_${(Date.now())}.zip`;
				document.body.appendChild(a);
				a.click();
				a.remove();
			} catch (error) {
				console.error('Error downloading the file', error);
			} finally {
				setLoading(false);
			}
		}
    };

    return (
			<>
				<Button
					label={loading ? 'Descargando... (puede tardar)' : 'Descargar TODO no caducado (zip)'}
					icon='pi pi-download'
					className='ml-2'
					onClick={() => handleDownload(proveedor.documento)}
				/>
				<BlockUI
					blocked={loading}
					fullScreen
					template={<i className='pi pi-spin pi-cog' style={{ fontSize: '3rem' }}></i>}
				/>
			</>
		);
}  ;

export default DescargaDocumentosProveedor;