import { Button } from "primereact/button";
import { descargaDocumentosPersonalService } from "../../../services/reactivaService";
import { useState } from "react";
import { BlockUI } from "primereact/blockui";

const DescargaDocumentosPersonal = ({persona}) => {

    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {       
        if (!loading){
            setLoading(true);
            try {
                const response = await descargaDocumentosPersonalService(persona.id);
                const blob = new Blob([response.data], { type: 'application/zip' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${persona.dni}_${persona.nombre}_${(Date.now())}.zip`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            } catch (error) {
                console.error('Error downloading the file', error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
			<>
				<Button
					label={loading ? 'Descargando... (puede tardar)' : 'Descargar TODO no caducado (zip)'}
					icon='pi pi-download'
					className='ml-2'
					onClick={() => handleDownload()}
				/>
				<BlockUI
					blocked={loading}
					fullScreen
					template={<i className='pi pi-spin pi-cog' style={{ fontSize: '3rem' }}></i>}
				/>
			</>
		);
}  ;

export default DescargaDocumentosPersonal;